<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>会员等级管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加会员等级</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="会员等级名称">
            </el-table-column>

            <el-table-column prop="level" label="会员等级"> </el-table-column>
            <el-table-column prop="start_standard" label="开始等级标准">
            </el-table-column>
            <el-table-column prop="standard" label="结束等级标准">
            </el-table-column>
            <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="level"
          label="会员等级"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.level"
            placeholder="请填写会员等级"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="name"
          label="等级名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.name"
            placeholder="请填写等级名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="start_standard"
          label="开始等级标准"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.start_standard"
            placeholder="请填写开始等级标准"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="standard"
          label="结束等级标准"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.standard"
            placeholder="请填写结束等级标准"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import lodash from 'lodash'
import {
  addGrade,
  gradeList,
  gradeInfo,
  editGrade,
  delgrade,
} from '@/api/member/listMember.js'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      imgList: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      shopList: [],
      btnLoading: false,

      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },

      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请填写会员等级',
          trigger: 'blur',
        },
        level: {
          required: true,
          message: '请填写会员等级',
          trigger: 'blur',
        },
        standard: {
          required: true,
          message: '请填写结束等级标准',
          trigger: 'blur',
        },
        start_standard: {
          required: true,
          message: '请填写开始等级标准',
          trigger: 'blur',
        },
      },
      title: '添加会员等级',
      addOrEdit: 1,
      formLabelWidth: '160px',

      form: {
        name: '',
        status: '',
        level: '',
        standard: '',
        start_standard: '',
      },
      tableData: [],
      timer: null,
    }
  },
  created() {
    this.getList()
    // this.getIntervalList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },

    async getList() {
      this.tableLoading = true
      const { data: res } = await gradeList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.message_num = this.tableData.length
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加会员等级'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        name: '',
        status: '',
        level: '',
        standard: '',
        start_standard: '',
      }

      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑会员等级'
      this.addOrEdit = 2
      const { data: res } = await gradeInfo({
        grade_id: row.grade_id,
      })
      console.log(res.data)
      //   this.form.class_name = res.data.class_name
      //   this.form.class_status = res.data.class_status
      this.form.standard = res.data.standard
      this.form.grade_id = res.data.grade_id
      this.form.name = res.data.name
      this.form.level = res.data.level
      this.form.status = res.data.status
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delgrade({
            grade_id: row.grade_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            let query = lodash.cloneDeep(this.form)

            var formData = new FormData()
            for (let key in query) {
              formData.append(key, query[key])
            }

            this.btnLoading = true
            const { data: res } = await addGrade(formData)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
            this.btnLoading = false
          } else {
            // 编辑

            let query = lodash.cloneDeep(this.form)

            var formData = new FormData()
            for (let key in query) {
              formData.append(key, query[key])
            }
            this.btnLoading = true
            if (this.isEdit === true) {
              const { data: res } = await editGrade(formData)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
            this.btnLoading = false
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
